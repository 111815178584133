<template>
  <div class="form-field card-number-field pt-3 pb-1 multiple-codes">
    <div>
      <label class="form-label" v-text="label"/>
      <span class="help-link" onclick="$('.card-bonus-popup').addClass('opened'); return false;"
            :title="labelHint"></span>

    </div>

    <code-input-hint v-bind="$props" :status="status"></code-input-hint>

    <div>
      <div class="position-relative">
      <template v-for="(code, idx) in model">
        <div class="form-field card-number-field" :key="code.rid">
          <div class="d-flex flex-row align-center">
            <div class="position-relative overflow-hidden flex-grow-1">
              <input :placeholder="codePlaceholder"
                     autocomplete="off"
                     :class="`serial-number my-0 ${className(code.status)} ${loading ? 'input_loading': ''}`"
                     type="text"
                     v-model="code.value"
                     :disabled="loading || (code.status === Statuses.OK)"
                     @keydown.enter.prevent="submit(idx)"
              />
              <input type="hidden"
                     :name="`${codeInputName}[]`"
                     :value="code.value"
              />
              <template v-if="!code.value">
                <div class="position-absolute btn-upload">
                  <span :class="`btn-upload__icon ${loading ? 'btn-upload__icon_disabled' : ''}`">
                    <i class="fa fa-camera mr-1"/>
                  </span>
                  <input type="file"
                         accept="image/*"
                         capture="environment"
                         class="btn-upload__input"
                         @change.prevent="(e) => uploadImage({event: e, idx})"
                         :disabled="loading">
                </div>
              </template>
              <div v-else class="action d-flex">
                <template v-if="code.value !== code.oldValue">
                  <button class="btn btn-sm btn-link" @click.prevent="submit(idx)">{{saveButton}}</button>
                </template>
                <template v-else-if="code.status === Statuses.OK">
                  <div style="color: #43A950" class="pt-2 px-3">✔︎</div>
                </template>
                <!--template v-if="status === Statuses.WARNING">
                  <div style="color: #F5B40B" class="pt-2 px-3">⚠︎︎</div>
                </template>
                <template v-if="status === Statuses.ERROR">
                  <div style="color: #d77" class="pt-2 px-3">✕</div>
                </template-->
              </div>
            </div>
            <div class="ml-1 mt-1">
              <button
                  :disabled="(model.length <= 1) || !code.oldValue || loading"
                  class="btn btn-sm"
                  :title="removeHint"
                  @click.prevent="remove(code.rid)">
                <i class="fa fa-trash text-danger"/>
              </button>
            </div>
          </div>
          <div v-if="code.message" :class="`form-field-notify form-field-${code.status.toLowerCase()}`"
               v-html="code.message"/>


        </div>
      </template>
        <LoadingOverlay v-if="loading" />
      </div>

      <codes-progress
          class="mr-3 pr-3"
          v-model="scoreValue"
          :progress-hint="progressHint"
      />
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import CodesProgress from './Progress';
import {Statuses} from '../ocrComponent/Statuses.model';
import Hint from '../ocrComponent/Hint.vue';
import axios from "axios";
import LoadingOverlay from "./LoadingOverlay.vue";

export default {
  props: {
    label: {required: true, type: String},
    labelHint: {required: false, type: String, default: 'Where is my card number?'},
    removeHint: {required: false, type: String, default: 'Remove'},
    progressHint: {required: false, type: String, default: 'Progress'},
    codes: {
      required: true,
      type: Array,
    },
    codePattern: {required: false, type: String},
    codeMask: {required: false, type: String},
    codePlaceholder: {required: false, type: String},
    codeTextHint: {required: true, type: String},
    codeInputName: {required: true, type: String},
    score: {required: false, type: [Number, String]},
    uniqueCodesError: {required: false, type: String, default: 'All codes should be unique'},
    maxSizeError: {required: false, type: String, default: 'File size should be less than 8Mb'},
    readerError: {required: false, type: String, default: 'Unable to recognize uploaded image. Please try another one'},
    verifyCodesError: {required: false, type: String, default: 'Not recognized. Please try again later'},
    saveButton: {required: false, type: String, default: 'Save'},
    sseEndpoint: {required: true, type: String},
    validateEndpoint: {required: true, type: String},
    detectTextEndpoint: {required: true, type: String},
  },

  components: {
    CodesProgress,
    CodeInputHint: Hint,
    LoadingOverlay,
  },

  data() {
    return {
      fileSizeLimit: 8, // Mb
      reader: null,

      list: JSON.parse(this.codes || '[]'),
      model: [],
      scoreValue: Number(this.score),

      loading: false,
      Statuses,
    };
  },

  mounted() {
    this.initSSERequest(this.sseEndpoint);

    this.model = this.list
        .map((item) => ({
          ...item,
          rid: this.rid(),
          oldValue: item.value,
        }));

    this.initFileReader();

    this.$nextTick(() => {
      this.updateSubmitFormStatus();
    });

    this.$nextTick(() => {
      this.addNewField();
    })
  },

  unmounted() {
    this.eventSource.close();
  },

  computed: {
    channelId: () => {
      return uuidv4();
    },

    className: () => (status) => {
      let res = '';

      const map = {
        [Statuses.ERROR]: 'error',
        [Statuses.WARNING]: 'warning',
        [Statuses.OK]: 'success',
      };

      if (Object.keys(map).includes(status)) {
        res = `input-${map[status]}`
      }

      return res;
    },

    disabled() {
      return (rid) => {
        let res = false;

        if (this.loading) {
          res = true;
        } else if (this.focused.length && !this.focused?.includes(rid)) {
          return true;
        }

        return res;
      }
    },

    headers() {
      return {
        "X-CSRF-Token":
            document.querySelector("meta[name='csrf-token']")?.content
      };
    }
  },

  methods: {
    rid() {
      return `input_${Math.random().toString(32).substring(2)}`;
    },

    initFileReader() {
      this.reader = new FileReader();
    },

    isFileSizeLimitOk(file) {
      return file && (file.size <= this.fileSizeLimit * 1024 * 1024);
    },

    submit() {
      const codes = this.model.map(code => code.value);
      const uniques = [...new Set(codes)];

      if (codes.length > uniques.length) {
        alert(this.uniqueCodesError);
      }

      this.verifyCodes(uniques);
    },

    remove(rid) {
      const idx = this.model.findIndex(item => item.rid === rid);

      this.model.splice(idx, 1);
      const codes = this.model.map(code => code.value);
      this.verifyCodes(codes);

      this.addNewField();
    },

    addNewField() {
      const emptyField = this.model.find(item => !item.value);
      if (!this.scoreValue || emptyField) {
        // do nothing
      } else {
        this.model.push({
          value: '',
          rid: this.rid(),
        });
      }
    },

    uploadImage({event, idx}) {
      let file = event.target.files[0];

      if (!this.isFileSizeLimitOk(file)) {
        alert(this.maxSizeError);
      } else {
        this.reader.onloadend = () => {
          const image = {
            name: file.name,
            url: URL.createObjectURL(file),
            file: file
          };
          this.sendImage({image, idx});
        };

        this.reader.onerror = () => {
          alert(this.readerError);
        };

        this.reader.readAsBinaryString(file);
      }
    },

    sendImage({image, idx}) {
      let fd = new FormData();
      const {protocol, host, pathname} = location;
      const headers = {
        ...this.headers,
        "Content-Type": "multipart/form-data"
      };

      fd.append("file", image.file);
      fd.append("pattern", this.codePattern);
      fd.append('channel', this.channelId);

      this.loading = true;

      axios({
        method: "post",
        url: this.detectTextEndpoint,
        data: fd,
        headers: headers
      }).then(res => {
        const {message} = res.data;

        if (message) {
          alert(message);
        }
      }).catch(() => {
        this.loading = false;
        alert(this.verifyCodesError);
      });
    },

    initSSERequest(endpoint) {
      const url = new URL(endpoint);
      url.searchParams.append('channel', this.channelId);
      this.eventSource = new EventSource(url.toString());

      this.eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const {status, message, serial_numbers} = data;

        if (message) {
          alert(message);
        }

        if (status === Statuses.OK && serial_numbers.length) {
          let list = this.model.map(item => item.value).filter((code) => !!code);
          list = [...list, ...serial_numbers];
          const uniques = [...new Set(list)];

          if (list.length > uniques.length) {
            alert(this.uniqueCodesError);
          }

          if (uniques.length) {
            return this.verifyCodes(uniques);
          }
        } else {
          this.loading = false;
        }
      };

      this.eventSource.onerror = (event) => {
        // todo: add some general handler if any
      };
    },

    verifyCodes(codes) {
      this.loading = true;
      const recaptchaResponsePromise = window.getRecaptchaResponse ? window.getRecaptchaResponse() : Promise.resolve();
      return recaptchaResponsePromise.then(response => axios({
        method: "post",
        url: this.validateEndpoint,
        headers: this.headers,
        data: {
          serial_numbers: codes.filter(code => !!code),
          'g-recaptcha-response': response,
        }
      })).then(res => {
        const {codes, score} = res.data;

        this.scoreValue = score;
        this.model = codes.map((code) => ({
          ...code,
          oldValue: code.value,
          rid: this.rid(),
        }));
      }).then(() => {
        this.updateSubmitFormStatus();
        this.addNewField();
      }).catch(() => {
        alert(this.verifyCodesError);
      }).finally(() => {
        this.loading = false;
      })
    },

    updateSubmitFormStatus() {
      const errored = this.model.find(code => code.status === Statuses.ERROR)
      const savePermitted = !errored && !this.scoreValue;

      const $save = $('.form-submit input');
      const $terms = $('.form-checkbox input');
      if (savePermitted) {
        $save.removeAttr('disabled');
        $terms.removeAttr('disabled');
      } else {
        $save.attr('disabled', 'disabled');
        $terms.prop('checked', false)
            .attr('disabled', 'disabled');
      }
    }
  },
}
</script>
